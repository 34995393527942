<template>
  <button class="p-1 pr-2 rounded-[2rem] flex gap-2 w-fit items-center" :class="bgTagName" @click="handleShowProfile()">
    <img class="w-6 aspect-square rounded-[50%]" :src="userInfo.avatar || avatar" alt="avatar" />
    <p :class="['text-[0.875rem] font-medium leading-none', textColor]">
      {{ userInfo.username || '--' }}
    </p>
    <img
      v-if="dataHome.tick_level === RankStatus.oneYear || dataHome.tick_level === RankStatus.fourYear"
      :src="dataHome.tick_level == RankStatus.oneYear ? MegaRank : UltimateRank"
      class="size-[1.5rem]"
      alt=""
    >
  </button>
</template>
<script setup>
import avatar from '@/assets/images/avt-default.png'
import MegaRank from '@/assets/images/mega_rank_tick.png'
import UltimateRank from '@/assets/images/ultimate_rank_tick.png'
import { RankStatus } from '~/constants/user'
import Store from '~/store/store'
import UserInfo from '~/store/user'

const router = useRouter()
const { showProfile } = storeToRefs(Store())
const { dataHome, userInfo, isBoost, autoBoost, boostingTime } = storeToRefs(UserInfo())
const dataShowVideo = [RankStatus.oneYear, RankStatus.fourYear]
const handleShowProfile = () => {
  showProfile.value = !showProfile.value
}
const bgTagName = computed(() => {
  if (path.value === '/home') {
    if (dataShowVideo.includes(dataHome.value.tick_level)) {
      return 'bg-[rgba(255,_255,_255,_0.20)] [box-shadow:0px_2px_10px_0px_rgba(25,_27,_31,_0.20)]'
    }
    if (isBoost.value) {
      return 'shadow_common bg-[rgba(255,255,255,0.10)]'
    } else {
      return 'bg-[linear-gradient(180deg,rgba(21,191,167,0.00)_0%,rgba(21,191,167,0.20)_100%)]'
    }
  } else if (path.value === '/shop' || path.value === '/mission') {
    return 'shadow_common bg-[rgba(0,0,0,0.4)]'
  } else if (path.value === '/partners' || path.value === '/leaderboard' || path.value === '/wallet') {
    return 'bg-[linear-gradient(180deg,rgba(21,191,167,0.00)_0%,rgba(21,191,167,0.20)_100%)]'
  } else if (path.value === '/onboard' || path.value === '/total-gum') {
    return 'shadow_common bg-[rgba(255,255,255,0.1)]'
  }
})
const path = ref(window.location.pathname)
const textColor = computed(() => {
  if (
    path.value === '/misson' ||
    path.value === '/leaderboard' ||
    path.value === '/partners' ||
    path.value === '/wallet' ||
    (path.value === '/home' && boostingTime.value <= 0 && !dataShowVideo.includes(dataHome.value.tick_level))
  ) {
    return 'text-gray-2'
  } else {
    return 'text-white-0'
  }
})
watch(
  router.currentRoute,
  () => {
    path.value = window.location.pathname
  },
  { immediate: true }
)
</script>
